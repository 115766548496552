import React, { useEffect, useLayoutEffect, useState } from 'react';

const App = ({ children, button }: { children: React.ReactNode, button: JSX.Element}) => {
  const [ flag, setFlag ] = useState(false)

  const onClick = () => {
    // return
    setFlag(!flag)
  }

  useEffect(() => {
  }, []);

  const componentDrawer = (
    <div
      className='element-anime-a'
      data-isdisplayed={flag}
      style={{
        // display: flag ? "block" : "none",
        position: "absolute",
        top: "0",
        left: "0",
        width: "100vw",
        height: "100dvh",
        backgroundColor: "#3bebe1c4",
        zIndex: 100,
      }}
      onClick={onClick}
    >
      {children}
    </div>
  )

  const componentButton = (
    <div onClick={onClick}
      style={{zIndex:99}}
    >
      {button}
    </div>
  )

  return { drawer: componentDrawer, button: componentButton }
}

export default App
