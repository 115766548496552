import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

const md = `# プライバシー・ポリシー

当社合同会社らっくらくは、お客様（当アプリの利用者）の個人情報について以下のとおりプライバシーポリシー（以下本ポリシー）を定めます。 本ポリシーでは当社が取得している「個人情報」「利用・共有方法」「お客様ご自身がどのように管理できるか」のご説明となります。

## 個人情報の管理

個人情報を取り扱うにあたっては、「個人情報の保護に関する法律」をはじめとする個人情報の保護に関する法令、ガイドラインおよび本プライバシーポリシーを遵守いたします。

## 個人情報の利用目的

弊社が提供するアプリ（以下、当アプリ）では、個人情報を利用する機能はございません。

## 個人情報の第三者提供について

当アプリでは、個人情報は適切に管理し、法律の適用を受ける場合や法的強制力のある請求の場合を除いて、第三者に開示することはありません。

## クッキー（Cookie）について

当アプリでは、一部のコンテンツについて情報の収集にクッキーを使用しています。
クッキーとは、WebサーバーからWebブラウザに送信されるデータのことであり、ユーザーがサイトを訪れた際に、そのユーザーのコンピュータ内に記録されます。ただし、当アプリがクッキーとして送るファイルには、ユーザー名やメールアドレスなど、個人を特定するものは一切含まれません。
また、当アプリでは、ユーザーの方々がどのようなサービスに興味をお持ちなのかを分析したり、ウェブ上での効果的な広告の配信のためにこれらを利用させていただく場合があります。

## 当アプリが使用しているアクセス解析ツールについて

当アプリでは、以下のアクセス解析ツールを利用しています。

1. 情報収集モジュール名称：Firebase Analytics
    - 情報モジュールの提供者：Google Inc.
    - 提供される利用者情報の項目：端末情報、本アプリの利用履歴情報（利用履歴）
    - 上記提供者による第三者提供の有無：Google Inc.
    - [上記提供者のプライバシーポリシー](https://policies.google.com/privacy)
2. 情報収集モジュール名称：Firebase Crashlytics
    - 情報モジュールの提供者：Google Inc.
    - 提供される利用者情報の項目：アプリ異常終了時の障害解析用ログ
    - 上記提供者による第三者提供の有無：Google Inc.
    - [上記提供者のプライバシーポリシー](https://policies.google.com/privacy)

## 当アプリの広告について

当アプリではアフィリエイトプログラムや第三者配信広告サービス「GoogleAdsense」を利用しています。
GoogleAdsenseでは広告配信プロセスにおいてデータを収集するために、Cookieを使用しています。GoogleでCookieを使用することにより、インターネットにおけるご自身のサイトや他のサイトへのアクセス情報に基づいてユーザーに広告を配信することが可能になります。
Cookieを使用しないように設定するには[こちら](https://policies.google.com/technologies/ads?hl=ja)をご参照ください。

- ### 情報の外部送信について

    広告を掲載する際に、当アプリでは広告の効果を測定するため、以下のような利用者のアクセス情報を外部へ送信しています。

- ### 送信情報

    閲覧したサイトのURL
    - クリック報酬型、又は成果報酬型広告を表示した日時
    - クリック報酬型、又は成果報酬型広告をクリックした日時
    - クリック報酬型、又は成果報酬型広告を計測するために必要なクッキー情報
    - クリック報酬型、又は成果報酬型広告表示時および広告をクリックした時のIPアドレス
    - クリック報酬型、又は成果報酬型広告表示時および広告クリック時に使用されたインターネット端末およびインターネットブラウザーの種類

    外部送信先：Google LLC

- ### 利用目的

    クリック報酬型広告の効果を測定および不正防止のために利用しております。
    当該情報は個人を特定する情報ではありません。
    また当該情報が上記の目的以外で利用されることは一切ありません。
    当アプリの広告は、バナーリンクおよびテキストリンクでの配信が主であり、閲覧者の閲覧履歴・個人データ等を取得・追跡するものではありません。

## 著作権について

当アプリの画面情報についての著作権は放棄しておりません。
著作権法により認められている引用の範囲である場合を除き当アプリの画像等の無断転載・使用を固く禁じます。

## 免責事項

当アプリに掲載されている内容につきましては、正しい情報を提供することに務めてはおりますが、提供している内容及びリンク先からいかなる損失や損害などの被害が発生したとしても、弊社では責任を負いかねますのでご了承ください。
本免責事項、および、当アプリに掲載しているコンテンツ・情報は、予告なしに変更・削除されることがあります。

## プライバシーポリシーの変更について

当アプリは、個人情報に関して適用される日本の法令を遵守するとともに、本ポリシーの内容を適宜見直しその改善に努めます。
修正された最新のプライバシーポリシーは常に本ページにて開示されます。

## お問い合わせ

当アプリへのお問い合わせは、[こちら](https://docs.google.com/forms/d/e/1FAIpQLSf1iEwOA9pBUwL9KFOrCPeeH05RUrUgz4Gz2r3tIxwIVvVaWA/viewform)のフォームから送信ください。

制定日: 2025年3月2日`

const Terms = () => {
  return (
    <div className="markdown userSelectEnableText">
      <ReactMarkdown remarkPlugins={[remarkGfm]}>{md}</ReactMarkdown>
    </div>
  )
}

export default Terms;
