import React, { createContext, useContext } from 'react';
import { HAKO_HOST, LAYOUT_SIZE_UNIT_HEIGHT, LAYOUT_SIZE_UNIT_WIDTH } from '../const';

export enum DispOrientation {
  landscape, // 横
  portrait, // 縦
}

type DispReferenced = {
  w: number,
  h: number,
  orientation: DispOrientation,
  unit: string
}

namespace Hooks {
  export namespace Interfaces {
    export interface state {
      display: DispReferenced
    }
    
    export interface stateAction {
      type: ActionType,
      payload: state,
    }
  }
  
  export enum ActionType {
    SET = 'SET',
  }
  
  const initial: Interfaces.state = {
    display: {
      w: 0,
      h: 0,
      orientation: DispOrientation.landscape,
      unit: LAYOUT_SIZE_UNIT_HEIGHT
    },
  }
  
  const reducer: React.Reducer<Interfaces.state, Interfaces.stateAction> = (state, action) => {
    switch (action.type) {
      case ActionType.SET:
        return {
          display: action.payload.display,
        }
      default:
        throw new Error(`未定義アクションのため処理を中断しました。(action type: ${action.type})`)
    }
  }
  
  export const Context = createContext({} as {
    state: Interfaces.state
    dispatch: React.Dispatch<Interfaces.stateAction>
  })
  
  export const State = ({ children }: { children?: React.ReactNode}) => {
    const [state, dispatch] = React.useReducer(reducer, initial)
    return (
      <Context.Provider value={{ state, dispatch }}>
        {children}
      </Context.Provider>
    )
  }

  export function useHooks() {
    const { state, dispatch } = useContext(Context)
  
    function set(w: number, h: number) {
      dispatch({
        type: ActionType.SET, payload: {
          ...state,
          display: {
            w,
            h,
            orientation: w >= h ? DispOrientation.landscape : DispOrientation.portrait,
            unit: w >= h ? LAYOUT_SIZE_UNIT_HEIGHT : LAYOUT_SIZE_UNIT_WIDTH,
          },
        }
      })
    }
  
    return { display: state.display, set }
  }
}

export default Hooks
