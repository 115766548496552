import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

const md = `# プライバシーポリシー

当社合同会社らっくらくは、お客様（当ホームページの訪問者）の個人情報について以下のとおりプライバシーポリシー（以下本ポリシー）を定めます。 本ポリシーでは当社が取得している「個人情報」「利用・共有方法」「お客様ご自身がどのように管理できるか」のご説明となります。
当ホームページにおいて個人情報を送信された場合、当社の個人情報の取扱いに関して同意をいただいたものとみなします。

## 個人情報の定義

本ポリシー上で用いる「個人情報」とは、個人情報の保護に関する法律（平成十五年法律第五十七号）（以下「個人情報保護法」といいます。）第2条第1項に規定する個人情報をいいます。

## 収集する個人情報等

お問い合わせフォームの送信時に、氏名・メールアドレスを取得することがあります。
また、お問い合わせ内容欄など任意の文字を入力する項目にお客様が氏名・メールアドレス以外の個人情報を入力する場合もあります。

## 利用目的

取得した個人情報を以下の目的で利用します。
- お問い合わせに関するご連絡をするため

## 利用目的内での利用

取得した個人情報は、個人情報保護法が定める例外に該当する場合を除き、本ポリシー上であらかじめ特定して公表した利用目的を達成するために必要な範囲内でのみ利用します。

## 第三者提供の制限

取得した個人情報は、個人情報保護法が定める例外に該当する場合を除き、あらかじめ本人の同意を得ないで第三者に提供しません。

## 個人情報の安全管理措置

取得した個人情報は紛失、毀損または漏洩を防止するために必要な安全管理措置を講じます。

## 保存期間

取得した個人情報は、取得から2年以上経過した場合は随時削除します。

## 個人情報の開示・訂正・利用停止等

ご本人から個人情報の内容の開示、内容の訂正、追加又は削除、利用の停止又は消去の請求があった場合には、個人情報保護法に基づき対応します。
お問い合わせフォームよりご連絡ください。

9.プライバシーポリシーの改定

社会情勢や事業内容の変化、個人情報保護法の改正等に対応するため、本ポリシーを、事前の予告なく変更することがあります。

制定：2025年3月7日
改定：2025年3月7日`

const Terms = () => {
  return (
    <div className="markdown">
      <ReactMarkdown remarkPlugins={[remarkGfm]}>{md}</ReactMarkdown>
    </div>
  )
}

export default Terms;