import React, { createContext, useContext } from 'react';
import { HAKO_HOST, LAYOUT_SIZE_UNIT_HEIGHT, LAYOUT_SIZE_UNIT_WIDTH } from '../const';

export enum RakkuMode  {
  default = "default",
  backgroundOnly = "backgroundOnly",
}

namespace Hooks {
  export namespace Interfaces {
    export interface state {
      types: string[]
      mode: string
    }
    
    export interface stateAction {
      type: ActionType,
      payload: state,
    }
  }
  
  export enum ActionType {
    SET = 'SET',
  }
  
  const initial: Interfaces.state = {
    types: [],
    mode: RakkuMode.default
  }
  
  const reducer: React.Reducer<Interfaces.state, Interfaces.stateAction> = (state, action) => {
    switch (action.type) {
      case ActionType.SET:
        return {
          types: action.payload.types,
          mode: action.payload.mode,
        }
      default:
        throw new Error(`未定義アクションのため処理を中断しました。(action type: ${action.type})`)
    }
  }
  
  export const Context = createContext({} as {
    state: Interfaces.state
    dispatch: React.Dispatch<Interfaces.stateAction>
  })
  
  export const State = ({ children }: { children?: React.ReactNode}) => {
    const [state, dispatch] = React.useReducer(reducer, initial)
    return (
      <Context.Provider value={{ state, dispatch }}>
        {children}
      </Context.Provider>
    )
  }

  export function useHooks() {
    const { state, dispatch } = useContext(Context)
  
    function append(val: string) {
      const list = state.types
      let mode: RakkuMode | undefined

      function clearTypes() {
        while (list.length > 0) {
          list.shift()
        }
      }

      if (val === "Backspace") {
        clearTypes()
      } else {
        list.push(val)
  
        if (process.env.NODE_ENV === "development") console.log(list.join(",").toLocaleLowerCase())
  
        if (list.join(",").toLocaleLowerCase() === "ArrowUp,ArrowUp,ArrowDown,ArrowDown,ArrowLeft,ArrowRight,ArrowLeft,ArrowRight,B,A".toLocaleLowerCase()) {
          mode = RakkuMode.backgroundOnly
          clearTypes()
        }
      }

      const set = {
        ...state,
        types: list
      }

      if (mode) {
        set.mode = mode
      }

      dispatch({
        type: ActionType.SET, payload: set
      })
    }
  
    return { state,  append}
  }
}

export default Hooks
