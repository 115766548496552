import React, { useEffect, useLayoutEffect, useState } from 'react';
// import LogoBack from "./assets/logo/back.svg";
import './App.css';
// import Westasabu from './components/effects/logo/westasabu'
// import Rotate3d from './components/effects/logo/rotate3d'
// import TextSplash from './components/effects/text/splash'
// import XTextTyping from './components/x/typing'
// import ClockA from './components/effects/clock/a'
// import { UtilRand } from './utils/rand';
// import { XRandom } from './components/x/random';
// import HooksTweetIds from './hooks/tweet'

import { BrowserRouter, Link, Routes, Route } from "react-router";
import Home from './components/Home'
import ServicesAppsNewDiatonicsTerms from './components/services/apps/newDiatonics/Terms'
import ServicesAppsNewDiatonicsPolicy from './components/services/apps/newDiatonics/Policy'
import ServicesFormPolicy from './components/services/form/Policy'
import Test from "./components/Test"

function App() {
  return (
    <BrowserRouter basename="/">
      <Routes>
        <Route path="/" element={<Home />}/>
        <Route path="servicesAppsNewDiatonicsTerms" element={<ServicesAppsNewDiatonicsTerms />}/>
        <Route path="servicesAppsNewDiatonicsPolicy" element={<ServicesAppsNewDiatonicsPolicy />}/>
        <Route path="servicesFormPolicy" element={<ServicesFormPolicy />}/>
        <Route path="test" element={<Test />}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
