import React, { useEffect, useLayoutEffect, useState } from 'react';
import HooksTweets from '../../hooks/tweet'
import { UtilRand } from '../../utils/rand';
import HooksDisplay, { DispOrientation } from '../../hooks/display'
import { LAYOUT_SIZE_UNIT_HEIGHT, LAYOUT_SIZE_UNIT_WIDTH } from '../../const';
import HooksTypeKeybord, { RakkuMode } from '../../hooks/typeKeybord'

function App(intervalSec: number, suffix = "") {
  const [ type, setType ] = useState("")
  const hooksTweets = HooksTweets.useHooks()
  const hooksDisplay = HooksDisplay.useHooks()
  const hooksTypeKeybord = HooksTypeKeybord.useHooks()

  const cursors = [
    // "✏︎",
    // "🖌️",
    // "🖊️",
    "🧙‍♀️",
    "🧙‍♂️",
    "🏃‍➡️",
    "🏃‍♀️‍➡️",
    // "🧑‍🦯",
    // "👩‍🦯",
    // "🦯",
  ]

  const fontFamily = "KiwiMaru-Regular"

  const setCursor = UtilRand.array(cursors)

  function draw(tweetIndex = 0, i = 0) {
    const cursor = (i % 2 > 0) ? setCursor : ""

    if (hooksTweets.state.tweets.length > 0 && hooksTweets.state.tweets[tweetIndex] && hooksTweets.state.tweets[tweetIndex].oembed) {
      const tweet = hooksTweets.state.tweets[tweetIndex]
      const datetime = new Date(tweet.created_at * 1000).toLocaleString("ja-JP", {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        hour12: false,
        minute: "numeric"
      })
      let html = tweet.oembed!.html.replace(/<blockquote [^>]+>/, "").replace("</blockquote>", "").replace("<a ", '<a target="_blank" ')
      const text = datetime + " X(旧Twitter)へポスト<br>" + html
      
      if (text.length < i) {
        if (hooksTweets.state.tweets.length - 1 === tweetIndex) {
          tweetIndex = 0
        } else {
          tweetIndex += 1
        }
        i = 0
        
        const call = () => {
          setType("")
          draw(tweetIndex, i)
        }
        setTimeout(call, 9000)
      } else {
        if (text.slice(i, i + 1) === "<") {
          while (text.slice(i, i + 1) !== ">") {
            i++;
          }
        }
        setType(text.slice(0, i) + cursor)
        i += 1

        const call = () => {
          draw(tweetIndex, i)
        }
        setTimeout(call, intervalSec * 1000)
      }

    }
    
  }

  useEffect(() => {
    if (hooksTweets.state.tweets.length > 0) {
      draw()
    }
  }, [hooksTweets.state.tweets])

  if (hooksTypeKeybord.state.mode === RakkuMode.default) {
    return (
      <div key={`typing_text${suffix}`} className='x-typing' style={{
        // display: "none",
        padding: `2${hooksDisplay.display.unit}`,
        position: "absolute",
        top: 0,
        left: 0,
        color: "#ffffff",
        textAlign: "left",
        fontSize: hooksDisplay.display.w > hooksDisplay.display.h ? `6${LAYOUT_SIZE_UNIT_HEIGHT}` : `6${LAYOUT_SIZE_UNIT_WIDTH}`,
        zIndex: 95,
        fontFamily,
        backgroundColor: "#0000008f",
        maxHeight: hooksDisplay.display.h,
        overflow: "auto",
      }}>
        <div dangerouslySetInnerHTML={{ __html: type }}/>
      </div>
    )
  } else {
    return <></>
  }
}

export default App;
