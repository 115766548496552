import React, { useEffect, useLayoutEffect, useState } from 'react';
import { UtilRand } from '../../../utils/rand';
import { Animix, UtilAnimation } from '../../../utils/animations/index';
import { AnimationTimingFunction } from '../../../utils/animations/props';

function App({ intervalSec, fontFamily, children, suffix = "" }: { intervalSec: number, fontFamily: string, children: JSX.Element, suffix: string}) {
// function App(intervalSec: number, fontFamily: string, elm: JSX.Element, suffix = "") {
  const [ styleAnima, setStyleAnima] = useState("")
  const [ elements, setElements] = useState<JSX.Element[]>([])
  
  function draw() {
    // const elementsNum = UtilRand.num(elementsNumMax) + 1
    let style: string = ""
    const elms: JSX.Element[] = []
    // const top = (h / 2) - (elementsSize / ratio / 2)
    // const left = (w / 2) - elementsSize * text.length / 2
    // console.log(top, left)
    const styleProps: React.CSSProperties = {
      color: UtilRand.color(250, true),
      // position: "absolute",
      // fontSize: elementsSize,
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      fontFamily,
      backfaceVisibility: "hidden",
      WebkitBackfaceVisibility: "hidden",
      WebkitFontSmoothing: "antialiased",
      MozOsxFontSmoothing: "grayscale",
    }

    const i = 0
    const className = `custom_animix_star_${suffix}${i}`
    const animix = new Animix()
    // let x = UtilRand.num(5)
    // if (UtilRand.num(1) === 1) x *= -1
    // let y = UtilRand.num(5)
    // if (UtilRand.num(1) === 1) y *= -1
    const elementScaleRatio = 1.0
    let deg = 720;
    let gyun = 30;
    // if (UtilRand.num(1) === 1) deg *= -1
    // console.log(x, y, deg)
    animix.addStyle(UtilAnimation.rotate3d.gyuru1(i, gyun, elementScaleRatio, suffix, deg), intervalSec, AnimationTimingFunction.linear, true)
    style += animix.getStyle(className)
    elms.push(<div className={className} style={styleProps}>{children}</div>)
  
    setStyleAnima(style)
    setElements(elms)

    setTimeout(draw, intervalSec * 1000)
  }

  useEffect(() => {
    draw()
  }, []);
  
  return (
    <div key={`splash_text${suffix}`}>
      <style>
        {styleAnima}
      </style>
      {elements}
    </div>
  );
}

export default App;
