import { UtilRand } from "../rand"

export namespace modules {
  export function gyun(index: number, gyun = 90, scale: number = 0.5, translateX = 0, translateY = 0, suffix = "", degMax = 350) {
    const name = `rotate3d_gyun_${suffix}${index}`
    const keyframes = `@keyframes ${name} {
  0% {
    transform: rotate3d(${UtilRand.float(1)}, ${UtilRand.float(1)}, ${UtilRand.float(1)}, 0deg) scale(1);
  }
  ${gyun}%{
    transform: rotate3d(${UtilRand.float(1)}, ${UtilRand.float(1)}, ${UtilRand.float(1)}, ${degMax}deg) scale(${scale}) translate(${translateX}px, ${translateY}px);
  }
  100% {
    transform: rotate3d(${UtilRand.float(1)}, ${UtilRand.float(1)}, ${UtilRand.float(1)}, 0deg) scale(1);
  }
}
`
    return { 
      name,
      keyframes
    }
  }

  export function gyun2(index: number, gyun = 90, scale: number = 0.5, translateX = 0, translateY = 0, suffix = "", degMax = 350) {
    const name = `rotate3d_gyun_${suffix}${index}`
    const keyframes = `@keyframes ${name} {
  0% {
    transform: rotate3d(${UtilRand.float(1)}, ${UtilRand.float(1)}, ${UtilRand.float(1)}, 0deg) scale(${1 / scale});
  }
  ${gyun}%{
    transform: rotate3d(${UtilRand.float(1)}, ${UtilRand.float(1)}, ${UtilRand.float(1)}, ${degMax}deg) scale(1) translate(${translateX}px, ${translateY}px);
  }
  100% {
    transform: rotate3d(${UtilRand.float(1)}, ${UtilRand.float(1)}, ${UtilRand.float(1)}, 0deg) scale(${1 / scale});
  }
}
`
    return { 
      name,
      keyframes
    }
  }

  export function gyuru1(index: number, gyun = 90, scale: number = 0.5, suffix = "", degMax = 361) {
    const name = `rotate3d_gyuru1_${suffix}${index}`
    const keyframes = `@keyframes ${name} {
  0% {
    transform: rotate(0deg) scale(1);
  }
  ${gyun}%{
    transform: rotate(${degMax / 2}deg) scale(${scale});
  }
  100% {
    transform: rotate(${degMax}deg) scale(1);
  }
}
`
    return { 
      name,
      keyframes
    }
  }
}

export default modules