import React, { useEffect, useLayoutEffect, useState } from 'react';
import Rotate3d from '../components/effects/logo/rotate3d'
import TextSplash from '../components/effects/text/splash'
import XTextTyping from '../components/x/typing'
import ClockA from '../components/effects/clock/a'
import { UtilRand } from '../utils/rand';
import HooksTweetIds from '../hooks/tweet'
import Drawer, { HomeDrawerButton, HomeDrawerComponent } from './drawer/home'
import HooksDisplay, { DispOrientation } from '../hooks/display'
import HooksTypeKeybord, { RakkuMode } from '../hooks/typeKeybord'
import { LAYOUT_SIZE_UNIT_HEIGHT } from '../const';

type SplashText = {
  text: string
  numMax: number
}

type SplashSetting = {
  fontFamilies: string[]
  messages: SplashText[]
  anniversary?: {[name:string]: SplashText[]}
}

const fontFamilies = [
  "MoiraiOne-Regular",
  // "RubikPuddles-Regular",
  // "FasterOne-Regular"
]

const fontFamiliesJa = [
  "Toroman"
]

const splashs: {[name:string]: SplashSetting} = {
  en: {
    fontFamilies: [
      "MoiraiOne-Regular",
      // "Toroman"
    ],
    messages: [
      {text: "synergy", numMax: 3},
      {text: "pop", numMax: 3},
      {text: "!", numMax: 2},
      {text: "?", numMax: 3},
      {text: "sustainable", numMax: 2},
      {text: "try and error", numMax: 5},
    ],
    anniversary: {
      "1/1": [
        {text: "HAPPY", numMax: 3},
        {text: "NEW YEAR!", numMax: 3},
        {text: new Date().getFullYear().toString(), numMax: 3},
      ]
    }
  },
  ja: {
    fontFamilies: [
      // "MoiraiOne-Regular",
      "Toroman"
    ],
    messages: [
      {text: "アジアゴールデンキャット", numMax: 3},
      {text: "アムールトラ", numMax: 3},
      {text: "アムールヒョウ", numMax: 3},
      {text: "アムールヤマネコ", numMax: 3},
      {text: "インドライオン", numMax: 3},
      {text: "オオヤマネコ", numMax: 3},
      {text: "カラカル", numMax: 3},
      {text: "キングチーター", numMax: 3},
      {text: "サーバルキャット", numMax: 3},
      {text: "スナドリネコ", numMax: 3},
      {text: "スナネコ", numMax: 3},
      {text: "スマトラトラ", numMax: 3},
      {text: "キングチーター", numMax: 3},
      {text: "チョウセントラ", numMax: 3},
      {text: "ツシマヤマネコ", numMax: 3},
      {text: "ヒョウ", numMax: 3},
      {text: "とら", numMax: 3},
      {text: "ベンガルトラ", numMax: 3},
      {text: "ベンガルヤマネコ", numMax: 3},
      {text: "ボブキャット", numMax: 3},
      {text: "ホワイトタイガー", numMax: 3},
      {text: "マヌルネコ", numMax: 3},
      {text: "ヨーロッパオオヤマネコc", numMax: 3},
      {text: "ライオン", numMax: 3},
      // {text: "いいね！", numMax: 3},
      {text: "㍉", numMax: 2},
      // {text: "☆", numMax: 3},
      {text: "クロヒョウ", numMax: 2},
      {text: "クロジャガー", numMax: 5},
      {text: "ユキヒョウ", numMax: 5},
      {text: "ジャガー", numMax: 5},
      {text: "ウンピョウ", numMax: 5},
      {text: "ピューマ", numMax: 5},
      {text: "チーター", numMax: 5},
      {text: "オセロット", numMax: 5},
      {text: "みけねこ", numMax: 5},
    ],
    anniversary: {
      "12/24": [
        {text: "メリー", numMax: 3},
        {text: "クリスマス", numMax: 3},
        {text: "オーナメント", numMax: 3},
        {text: "リース", numMax: 3},
        {text: "ジングル", numMax: 3},
        {text: "ベル", numMax: 3},
        {text: "キャンドル", numMax: 3},
        {text: "キャロル", numMax: 3},
        {text: "クッキー", numMax: 3},
        {text: "スノーマン", numMax: 3},
        {text: "グリーティング", numMax: 3},
        {text: "カード", numMax: 3},
        {text: "🦌", numMax: 3},
        {text: "🎅", numMax: 3},
        {text: "🎁", numMax: 3},
        {text: "しちめんちょう🦃", numMax: 3},
        {text: "チョコレート", numMax: 3},
        {text: "ブッシュド", numMax: 3},
        {text: "ノエル", numMax: 3},
        {text: "🎂", numMax: 3},
        {text: "⛄️", numMax: 3},
        {text: "🌲", numMax: 3},
        {text: "ガーリック", numMax: 3},
        {text: "シュリンプ🦐", numMax: 3},
        {text: "フライド", numMax: 3},
        {text: "チキン🍗", numMax: 3},
        {text: "きよし", numMax: 3},
        {text: "このよる🌃", numMax: 3},
        {text: "ホワイト", numMax: 3},
      ],
      "12/25": [
        {text: "メリー", numMax: 3},
        {text: "クリスマス", numMax: 3},
        {text: "🦌", numMax: 3},
        {text: "🎅", numMax: 3},
        {text: "🎁", numMax: 3},
        {text: "しちめんちょう🦃", numMax: 3},
        {text: "チョコレート", numMax: 3},
        {text: "ブッシュド", numMax: 3},
        {text: "ノエル", numMax: 3},
        {text: "🎂", numMax: 3},
        {text: "⛄️", numMax: 3},
        {text: "🌲", numMax: 3},
        {text: "ガーリック", numMax: 3},
        {text: "シュリンプ🦐", numMax: 3},
        {text: "フライド", numMax: 3},
        {text: "チキン🍗", numMax: 3},
        {text: "きよし", numMax: 3},
        {text: "このよる🌃", numMax: 3},
        {text: "ホワイト", numMax: 3},
      ],
      "1/1": [
        {text: "あけまして", numMax: 3},
        {text: "おめでとうございます", numMax: 3},
        {text: "A", numMax: 3},
        {text: "HAPPY", numMax: 3},
        {text: "NEW YEAR!", numMax: 3},
        {text: "🎍", numMax: 3},
        {text: "🍊", numMax: 3},
        {text: new Date().getFullYear().toString(), numMax: 3},
      ]
    }
  }
}

const langs = [
  "en",
  "ja"
]
const lang = langs[Math.floor(Math.random() * (langs.length))]
const splash = splashs[lang]
const fontFamily = splash.fontFamilies[Math.floor(Math.random() * (splash.fontFamilies.length))]
let splashDefines = splash.messages
const today = `${new Date().getMonth() + 1}/${new Date().getDate()}`
if (splash.anniversary && splash.anniversary[today]) {
  splashDefines = splash.anniversary[today]
}

const sai = UtilRand.num(1)

function Home() {
  const ratio = 400 / 114
  const intervalSec = UtilRand.num(9) + 1;
  const hooksTweetIds = HooksTweetIds.useHooks()
  const hooksDisplay = HooksDisplay.useHooks()
  const hooksTypeKeybord = HooksTypeKeybord.useHooks()

  const onKeyDown = (ev: KeyboardEvent) => {
    console.log(ev.key)
    hooksTypeKeybord.append(ev.key)
  }

  useEffect(() => {
    document.addEventListener(
      "keydown",
      onKeyDown,
    )

    const f = async () => {
      await hooksTweetIds.get("1831578876524556289", 5)
    }
    f()
  }, [])

  useLayoutEffect(() => {
    const updateSize = (): void => {
      hooksDisplay.set(window.innerWidth, window.innerHeight)
    }
    window.addEventListener('resize', updateSize)
    window.addEventListener('orientationchange', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  let xPanelMaxHeight = `38${LAYOUT_SIZE_UNIT_HEIGHT}`

  if (
    window.screen.orientation.type === "portrait-primary" ||
    window.screen.orientation.type === "portrait-secondary"
  ) {
    xPanelMaxHeight = `33${LAYOUT_SIZE_UNIT_HEIGHT}`
  }

  const splashTextCount = 30
  const splashTexts: SplashText[] = []

  for (let i = 0; i < splashTextCount; i++) {
    splashTexts.push(UtilRand.array(splashDefines))
  }

  const drawer = Drawer()

  return (
    <div className="App" style={{position: "relative", overflow: "hidden"}}>
      <header className="App-header" style={{position: "relative",}}>
        <div style={{position: "relative", opacity: 0.9}}>
          {
            splashTexts.map((text, i) => {
              return TextSplash(hooksDisplay.display.w - 100, hooksDisplay.display.w / ratio, UtilRand.num(9) + 10, fontFamily, text.text, text.numMax, `_splash_${i}`)
            })
          }
          {
            Rotate3d(hooksDisplay.display.w - 100, hooksDisplay.display.w / ratio, intervalSec)
          }
        </div>
        {ClockA(hooksDisplay.display.w - 100, hooksDisplay.display.w / ratio , fontFamily)}
        {XTextTyping(0.16)}
        {(hooksTypeKeybord.state.mode === RakkuMode.default) && (
          drawer.drawer
        )}
        {(hooksTypeKeybord.state.mode === RakkuMode.default) && (          
          <div
            style={{
              position: "absolute",
              right: `10${hooksDisplay.display.unit}`,
              bottom: `10${hooksDisplay.display.unit}`,
              zIndex: 99,
              width: `18${hooksDisplay.display.unit}`,
              height: `18${hooksDisplay.display.unit}`,
            }}
          >
            {drawer.button}
          </div>
        )}
      </header>
    </div>
  )
}

export default Home
