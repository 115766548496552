import React, { MouseEvent, useEffect, useLayoutEffect, useRef, useState } from 'react';
import Drawer from './a'
import Spin from '../effects/widget/spin'
import { RippleButton } from '../buttons/ripple';
import HooksDisplay, { DispOrientation } from '../../hooks/display'

export function HomeDrawerComponent() {
  const hooksDisplay = HooksDisplay.useHooks()

  return (
    <div 
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100dvh",
      }}
    >
      <div
        className='display-baba-n pan-left'
        style={{
          position: "absolute",
          right: 0,
          top: "20dvh",
          margin: "auto",
          height: "fit-content",
          backgroundColor: "#000000b8",
          borderTopLeftRadius: `1${hooksDisplay.display.unit}`,
          borderBottomLeftRadius: `1${hooksDisplay.display.unit}`,
          padding: `4${hooksDisplay.display.unit}`,
        }}
      >
        <a href="https://docs.google.com/forms/d/e/1FAIpQLSf1iEwOA9pBUwL9KFOrCPeeH05RUrUgz4Gz2r3tIxwIVvVaWA/viewform" target="_blank">
          らっくらくへお問い合わせ
        </a>
      </div>
      <div
        className='display-baba-n pan-right'
        style={{
          position: "absolute",
          left: 0,
          bottom: 0,
          margin: "auto",
          height: "fit-content",
          backgroundColor: "#000000b8",
          borderTopRightRadius: `1${hooksDisplay.display.unit}`,
          padding: `4${hooksDisplay.display.unit}`,
          textAlign: "left",
        }}
      >
        <div>
          合同会社らっくらく（英名: Rakkuraku llc）
        </div>
        <div>
          代表社員: 土岐 秀憲
        </div>
        <div>
          設立日: 2024年4月22日
        </div>
        <div>
          住所: 〠252-0301 神奈川県相模原市南区鵜野森2-25-34-4
        </div>
      </div>
    </div>
  )
}

export function HomeDrawerButton() {
  const [ unit, setUnit ] = useState("dvh")
  const hooksDisplay = HooksDisplay.useHooks()

  useEffect(() => {
    setUnit(hooksDisplay.display.orientation === DispOrientation.landscape ? "dvh" : "vw")
  }, [hooksDisplay.display]);

  const spinContents = (
    <div
      className="satellite-circle"
      style={{
        backgroundColor: "#0ad998",
      }}
    >
      ⚫︎
    </div>
  )

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        cursor: "pointer",
      }
    }>
      <Spin intervalSec={5} fontFamily='MoiraiOne-Regular' suffix=''>
        {spinContents}
      </Spin>
      <p style={{
        position: "absolute",
        top: "0px",
        left: "0px",
        right: "0px",
        bottom: "0px",
        height: `fit-content`,
        margin: "auto",
        userSelect: "none",
        cursor: "pointer",
      }}>About</p>
    </div>
  )
}

function App() {
  return Drawer({children: <HomeDrawerComponent/>, button: <HomeDrawerButton/>})
}

export default App;
