import React, { createContext, useContext } from 'react';
import { HAKO_HOST } from '../const';

type ApiXTweetReferenced = {
  id: number,
  type: string,
}

export type ApiXOembedResponse = {
  url: string,
  author_name: string,
  author_url: string,
  html: string,
  width: number | null,
  height: number | null,
  type: string,
  cache_age: string,
  provider_name: string,
  provider_url: string,
  version: string
}

export type ApiXTweet = {
  id: number,
  created_at: number,
  text: string,
  referenced: ApiXTweetReferenced[] | undefined
  oembed: ApiXOembedResponse | undefined
}

namespace Hooks {
  export namespace Interfaces {
    export interface state {
      tweets: ApiXTweet[]
    }
    
    export interface stateAction {
      type: ActionType,
      payload: state,
    }
  }
  
  export enum ActionType {
    SET = 'SET',
  }
  
  const initial: Interfaces.state = {
    tweets: [],
  }
  
  const reducer: React.Reducer<Interfaces.state, Interfaces.stateAction> = (state, action) => {
    switch (action.type) {
      case ActionType.SET:
        return {
          tweets: action.payload.tweets,
        }
      default:
        throw new Error(`未定義アクションのため処理を中断しました。(action type: ${action.type})`)
    }
  }
  
  export const Context = createContext({} as {
    state: Interfaces.state
    dispatch: React.Dispatch<Interfaces.stateAction>
  })
  
  export const State = ({ children }: { children?: React.ReactNode}) => {
    const [state, dispatch] = React.useReducer(reducer, initial)
    return (
      <Context.Provider value={{ state, dispatch }}>
        {children}
      </Context.Provider>
    )
  }

  export function useHooks() {
    const { state, dispatch } = useContext(Context)
  
    function set(tweets: ApiXTweet[]) {
      dispatch({
        type: ActionType.SET, payload: {
          ...state,
          tweets,
        }
      })
    }
  
    async function get(userId: string, limit = 5) {
      try {
        const res = await fetch(`${HAKO_HOST}/python_fluid/getUserTweets`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            userId,
            limit
          })
        })
        const tweets: ApiXTweet[] = JSON.parse(await res.text())
        set(tweets)
      } catch (error) {
        throw new Error()
        // エラーハンドリング
      }
    }
  
    return { state, get }
  }
}

export default Hooks
