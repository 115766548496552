import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

const md = `# 利用規約

この利用規約（以下，「本規約」といいます。）は，合同会社らっくらく（以下，「当社」といいます。）が提供するアプリ（以下、「本アプリ」といいます。）の利用条件を定めるものです。登録ユーザーの皆さま（以下，「ユーザー」といいます。）には，本規約に従って，本アプリをご利用いただきます。

## 第一条 適用

1. 本規約は，ユーザーと当社との間の本アプリの利用に関わる一切の関係に適用されるものとします。
2. 当社は本アプリに関し，本規約のほか，ご利用にあたってのルール等，各種の定め（以下，「個別規定」といいます。）をすることがあります。これら個別規定はその名称のいかんに関わらず，本規約の一部を構成するものとします。
3. 本規約の規定が前条の個別規定の規定と矛盾する場合には，個別規定において特段の定めなき限り，個別規定の規定が優先されるものとします。

## 第二条 利用登録

1. 本アプリにおいては，利用希望者が本規約に同意の上，本アプリをインストールすることによって利用登録が完了するものとします。
2. 当社は，利用者に以下の事由があると判断した場合，本アプリの利用を制限することがあり，その理由については一切の開示義務を負わないものとします。
    1. 本規約に違反したことがある者がインストールしようとした場合
    2. その他，当社が利用を相当でないと判断した場合
	
## 第三条 利用料金および支払方法

ユーザーは，本アプリの有料部分の対価として，当社が別途定める利用料金を，当社が指定する方法により支払うものとします。

## 第四条 支払い、キャンセル、クーリングオフ

1. ユーザーは、アプリ内課金、または有料サブスクリプションを購入した場合、購入後14日以内（「クーリングオフ期間」）に考えを変えて全額返金を受ける権利があります。
2. 有料サブスクリプションをお持ちの場合、現在のサブスクリプション期間の終了前にサブスクリプションページから有料サブスクリプションをキャンセルしない限り、当社への支払いはサブスクリプション期間の終了時に自動的に更新されます。キャンセルは現在のサブスクリプション期間の最終日の翌日に有効になり、無料サービスにダウングレードされます。現在の契約期間の終了の48時間未満にキャンセルが行われた場合、支払いプロバイダーがすでに契約延長を開始している可能性があります。この場合、解約にもかかわらず、それぞれの金額は引き続き引き落とされます。キャンセルと支払い処理の間にそのような重複がある場合、金額は返金されます。ただし、クーリングオフ期間の終了後（該当する場合）、および/またはサブスクリプション期間の終了前に支払いをキャンセルおよび/または利用規約を終了した場合、当社はすでに支払われたサブスクリプション料金を返金しません。
3. 当社は有料サブスクリプションの価格を随時変更することがあります。価格変更があった場合はお客様に通知します。有料サブスクリプションの価格変更は、価格変更日の次のサブスクリプション期間の開始時に有効になります。価格変更の有効後も flowkey サービスを継続して使用することにより、お客様は新しい価格に同意したことになります。

- 返金の方法

    - iOS（iPhone または iPad）
      [Apple サポートページ](https://support.apple.com/HT204084)に記載された手順をご確認ください。

- サブスクリプションの解約方法

    - iOS（iPhone または iPad）
      [Appleのサブスクリプションを解約](https://support.apple.com/ja-jp/118428)に記載された手順をご確認ください。

## 第五条 禁止事項

ユーザーは，本アプリの利用にあたり，以下の行為をしてはなりません。

1. 法令または公序良俗に違反する行為
2. 犯罪行為に関連する行為
3. 本アプリの内容等，本アプリに含まれる著作権，商標権ほか知的財産権を侵害する行為
4. 当社，ほかのユーザー，またはその他第三者のサーバーまたはネットワークの機能を破壊したり，妨害したりする行為
5. 本アプリの画面情報などを商業的に利用する行為
6. 当社のサービスの運営を妨害するおそれのある行為
7. 不正アクセスをし，またはこれを試みる行為
8. 不正な目的を持って本アプリを利用する行為
9. 本アプリの他のユーザーまたはその他の第三者に不利益，損害，不快感を与える行為
10. 当社のサービスに関連して，反社会的勢力に対して直接または間接に利益を供与する行為
11. その他，当社が不適切と判断する行為

## 第六条 本サービスの提供の停止等

1. 当社は，以下のいずれかの事由があると判断した場合，ユーザーに事前に通知することなく本アプリの全部または一部の提供を停止または中断することができるものとします。
    1. 本アプリにかかるコンピュータシステムの保守点検または更新を行う場合
    2. 地震，落雷，火災，停電または天災などの不可抗力により，本アプリの提供が困難となった場合
    3. コンピュータまたは通信回線等が事故により停止した場合
    4. その他，当社が本アプリの提供が困難と判断した場合
2. 当社は，本アプリの提供の停止または中断により，ユーザーまたは第三者が被ったいかなる不利益または損害についても，一切の責任を負わないものとします。

## 第七条 保証の否認および免責事項

1. 当社は，本アプリに事実上または法律上の瑕疵（安全性，信頼性，正確性，完全性，有効性，特定の目的への適合性，セキュリティなどに関する欠陥，エラーやバグ，権利侵害などを含みます。）がないことを明示的にも黙示的にも保証しておりません。
2. 当社は，本アプリに起因してユーザーに生じたあらゆる損害について、当社の故意又は重過失による場合を除き、一切の責任を負いません。ただし，本アプリに関する当社とユーザーとの間の契約（本規約を含みます。）が消費者契約法に定める消費者契約となる場合，この免責規定は適用されません。
3. 前項ただし書に定める場合であっても，当社は，当社の過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害のうち特別な事情から生じた損害（当社またはユーザーが損害発生につき予見し，または予見し得た場合を含みます。）について一切の責任を負いません。また，当社の過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害の賠償は，ユーザーから当該損害が発生した月に受領した利用料の額を上限とします。
4. 当社は，本アプリに関して，ユーザーと他のユーザーまたは第三者との間において生じた取引，連絡または紛争等について一切責任を負いません。

## 第八条 サービス内容の変更等

当社は，ユーザーへの事前の告知をもって、アプリの内容を変更、追加または廃止することがあり、ユーザーはこれを承諾するものとします。

## 第九条 利用規約の変更

1. 当社は以下の場合には、ユーザーの個別の同意を要せず、本規約を変更することができるものとします。
    1. 本規約の変更がユーザーの一般の利益に適合するとき。
    2. 本規約の変更が本サービス利用契約の目的に反せず、かつ、変更の必要性、変更後の内容の相当性その他の変更に係る事情に照らして合理的なものであるとき。
2. 当社はユーザーに対し、前項による本規約の変更にあたり、事前に、本規約を変更する旨及び変更後の本規約の内容並びにその効力発生時期を通知します。

## 第十条 個人情報の取扱い

当社は，本アプリの利用によって取得する個人情報については，当社「プライバシーポリシー」に従い適切に取り扱うものとします。
	
## 第十一条 準拠法・裁判管轄

1. 本規約の解釈にあたっては，日本法を準拠法とします。
2. 本アプリに関して紛争が生じた場合には，当社の本店所在地を管轄する裁判所を専属的合意管轄とします。

制定日: 2025年3月2日`

const Terms = () => {
  return (
    <div className="markdown userSelectEnableText">
      <ReactMarkdown remarkPlugins={[remarkGfm]}>{md}</ReactMarkdown>
    </div>
  )
}

export default Terms;
